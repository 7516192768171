import BannerCTA from "@components/BannerCTA/BannerCTA"
import IntroVideo from "@components/IntroVideo/IntroVideo"
import Layout from "@components/Layout/Layout"
import SplitCard from "@components/SplitCard/SplitCard"
import Welcome from "@components/Welcome/Welcome"
import "@styles/global.scss"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

export default () => {
  const PageTitle: string =
    "Vehicle Training & Restoration Facility | Hagerty Learning Garage"
  const metaDescription: string =
    "The Hagerty Learning Garage houses over 60 automobiles from every era. There’s always something happening at the Garage, our employee training and vehicle restoration facility."

  const fluidImage = useStaticQuery(graphql`
    query {
      introVideo: file(relativePath: { eq: "garage-intro.mp4" }) {
        publicURL       
      }
      videoPoster: file(relativePath: { eq: "poster.png" }) {
        childImageSharp {
          fixed(width: 1200) {
            src
          }
        }
      }
      heroImg: file(relativePath: { eq: "home-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      welcomeImg: file(relativePath: { eq: "car-people.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      foundersStoryImg: file(relativePath: { eq: "mckeel-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      whatsHappeningImg: file(relativePath: { eq: "whats-happening.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout
      metaPageTitle={PageTitle}
      metaDescription={metaDescription}
      darkTheme={true}
    >
      <IntroVideo
        videoSrc={fluidImage.introVideo.publicURL}
        poster={fluidImage.videoPoster.childImageSharp.fixed.src}
      />
      <Welcome
        title="At Hagerty, we are car people."
        body="As a training facility and automotive library, the Hagerty Learning Garage never looks the same twice. We’re more than a classic car insurance company. We live cars, and we’re dedicated to preserving them here."
        ctaLink="/vehicles"
        ctaDescription="View our vehicles"
        fluidImg={fluidImage.welcomeImg.childImageSharp.fluid}
      />
      <SplitCard
        title="Founder’s story"
        body="McKeel started his first restoration project at the age of 16. That car is still with us in the Learning Garage.  We’ve gathered a fleet of classic cars from every era, and we pride ourselves on tracking down the details of each vehicle’s past life."
        ctaLink="/about"
        ctaDescription="About the Learning Garage"
        fluidImg={fluidImage.foundersStoryImg.childImageSharp.fluid}
        rightAlignText={true}
      />
      <SplitCard
        title="What we're learning"
        body="We breathe new life into project vehicles here on-site, bringing each back to their former glory and sharing their stories along the way.  Learn more about how we use this space."
        ctaLink="/locations"
        ctaDescription="Behind the scenes"
        fluidImg={fluidImage.whatsHappeningImg.childImageSharp.fluid}
      />
      <BannerCTA />
    </Layout>
  )
}
