import React from "react"
import "./IntroVideo.scss"

interface IIntroVideo {
  videoSrc: any
  poster: any
}

const IntroVideo: React.FC<IIntroVideo> = props => {
  return (
    <section className="wrapper intro-video">
      <div className="intro-video--background-hero" />
      <div className="intro-video__hero">
        <div
          className="intro-video__hero--center"
          style={{ padding: `32px 0px 0px 0px` }}
        >
        <video playsInline controls poster={props.poster} style= {{ width: '100%', height: '100%'}}>
            <source src={props.videoSrc} type="video/mp4" />                
        </video>
        </div>
      </div>
    </section>
  )
}

export default IntroVideo
