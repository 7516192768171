import ContentImage from "@components/ContentImage/ContentImage"
import { Link } from "gatsby"
import React from "react"
import "./Welcome.scss"

interface IWelcome {
  title: string
  body: string
  ctaLink: string
  ctaDescription: string
  fluidImg: any
}

const Welcome: React.FC<IWelcome> = props => {
  return (
    <article className="wrapper welcome">
      <div className="col col-2">
        <div className="col--left">
          <h1 className="f-h1 f-h-lite">{props.title}</h1>
          <p className="f-body1 f-body-lite constrain-small">{props.body}</p>
          <Link to={props.ctaLink} className="f-link link--strong">
            {props.ctaDescription}
          </Link>
        </div>
        <div className="col--right">
          <ContentImage fluidImg={props.fluidImg} />
        </div>
      </div>
    </article>
  )
}

export default Welcome
